export const doLoginAPI = async (data) => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  return fetch("https://account2.inovyo.com/api/login", {
    method: "POST",
    headers,
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      if (res.ok) return res.json();
      return res.json().then(() => {
        throw new Error(403);
      });
    })
    .catch(() => {
      throw new Error(403);
    });
};
