import api, { headers } from "../../services/api";

export const getDetailsAPI = async () => {
  return api.get("conta/detalhes", { headers });
};

export const getAcessosAPI = async () => {
  return api.get("conta/acesso-api", { headers });
};

export const updatePasswordAPI = async (passData) => {
  return api.post("alterar-senha", passData, { headers });
};
