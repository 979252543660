import { toast } from "react-toastify";

//utils
import { clearStorages } from "../../utils/Storage";
import { clearSessions } from "../../utils/Session";
import { clearCookies } from "../../utils/Cookie";

//redux
import {
  getDetailsAPI,
  getAcessosAPI,
  updatePasswordAPI,
} from "./settings.service";
import { setAccountDetailsRedux, setAccessAPIRedux } from "./settings.slice";
import { setLoadingRedux } from "../application/app.slice";

export const getDetails = () => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getDetailsAPI()
      .then(({ data }) => {
        dispatch(setAccountDetailsRedux(data));
      })
      .catch(() => {
        toast.error("Falha ao carregar dados da conta!");
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};

export const getAcessos = () => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    getAcessosAPI()
      .then(({ data }) => {
        dispatch(setAccessAPIRedux(data));
      })
      .catch(() => {
        toast.error("Falha ao carregar dados de integração!");
      })
      .finally(() => {
        dispatch(setLoadingRedux(false));
      });
  };
};

export const updatePassword = (passData) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    updatePasswordAPI(passData)
      .then(({ data }) => {
        toast.success("Senha alterada com sucesso! Aguarde...");
        setTimeout(() => {
          clearStorages();
          clearSessions();
          clearCookies();
          window.location.href = "/login";
          return;
        }, 2000);
      })
      .catch(() => {
        toast.error("Falha ao atualizar sua senha. Tente novamente!");
        dispatch(setLoadingRedux(false));
      });
  };
};
