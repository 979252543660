/**
 * Cria um session se ele não existir. O padrão é vazio, mas
 * ele pode receber um valor padrão desde que seja informado
 * @param {string} name - nome do session
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const createSessionIfNotExist = (name, value, isJson = false) => {
  const storeFilter = sessionStorage.getItem(name);
  if (storeFilter === null) {
    sessionStorage.setItem(
      name,
      value !== "" ? (isJson ? JSON.stringify(value) : value) : ""
    );
  }
};

/**
 * Cria um session
 * @param {string} name - nome do session
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const setSession = (name, value, isJson = false) => {
  sessionStorage.setItem(name, isJson ? JSON.stringify(value) : value);
};

/**
 * Pega o valor de um session e retorna se ele existir
 * @param {string} name - nome do session
 * @param {boolean} isJson - se verdadeiro, transforma uma string em objeto
 * @returns {string|object}
 */
export const getSession = (name, isJson = false) => {
  const session = sessionStorage.getItem(name);
  return session !== null && session !== ""
    ? isJson
      ? JSON.parse(session)
      : session
    : "";
};

/**
 * Exclui um session específico
 * @param {string} name
 */
export const deleteSession = (name) => {
  sessionStorage.removeItem(name);
};

/**
 * Apaga todos os sessions do navegador
 */
export const clearSessions = () => {
  sessionStorage.clear();
};

/**
 * Chave para descriptograr o session storage
 */
export const decryptKeySession = () => {
  return "8zxsFuG58FNS7RT";
};
