import { useEffect, useContext, useState } from "react";
import { Interweave } from "interweave";
import {
  Card,
  Space,
  Select,
  Input,
  Divider,
  Empty,
  Menu,
  Popover,
  Modal,
  Badge,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  AppstoreOutlined,
  MoreOutlined,
  EditOutlined,
  ReloadOutlined,
  SendOutlined,
  ExportOutlined,
} from "@ant-design/icons";

//components
import { Context } from "../../providers/AuthContext";

//redux
import {
  fetchHub,
  reloadApp,
  publishApp,
} from "../../redux/general/general.actions";
import {
  surveysRedux,
  qlikRedux,
  reloadRedux,
  setQlikRedux,
  setReloadRedux,
} from "../../redux/general/general.slice";
import { loadingRedux } from "../../redux/application/app.slice";

//styles
import "../../assets/styles/general/home.css";

//images
import axIcon from "../../assets/images/circle-ax.svg";
import bxIcon from "../../assets/images/circle-bx.svg";
import cxIcon from "../../assets/images/circle-cx.svg";
import cxcIcon from "../../assets/images/circle-cxc.svg";
import exIcon from "../../assets/images/circle-ex.svg";
import slxIcon from "../../assets/images/circle-slx.svg";
import ahIcon from "../../assets/images/circle-ah.svg";

const HomePage = () => {
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  const surveys = useSelector(surveysRedux);
  const loading = useSelector(loadingRedux);
  const qlik = useSelector(qlikRedux);
  const reload = useSelector(reloadRedux);

  const [surveysClone, setSurveysClone] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [category, setCategory] = useState("*");
  const [process, setProcess] = useState(false);
  const [openReload, setOpenReload] = useState(false);

  const badgeReload = {
    ERROR500: {
      label: "ERRO",
      color: "red",
    },
    SUCCEEDED: {
      label: "SUCESSO",
      color: "green",
    },
    FAILED: {
      label: "ATENÇÃO",
      color: "orange",
    },
  };

  const icons = {
    ax: { icon: axIcon, label: "After Experience" },
    bx: { icon: bxIcon, label: "Before Experience" },
    ex: { icon: exIcon, label: "Employee Experience" },
    cx: { icon: cxIcon, label: "Customer Experience" },
    cxc: { icon: cxcIcon, label: "Customer Experience Consulting" },
    slx: { icon: slxIcon, label: "Social Listening Experience" },
    ah: { icon: ahIcon, label: "Adhoc" },
  };

  useEffect(() => {
    dispatch(fetchHub());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveys !== null) setSurveysClone(surveys);
    // eslint-disable-next-line
  }, [surveys]);

  useEffect(() => {
    if (searchVal.length >= 3) {
      let doSearch = setTimeout(() => {
        let surveysCloneCopy = [];
        surveys.forEach((s) => {
          const lowerText = s.label.toLowerCase();
          if (lowerText.indexOf(searchVal) >= 0) {
            surveysCloneCopy.push(s);
          }
        });
        setSurveysClone(surveysCloneCopy);
        setProcess(false);
      }, 1000);
      return () => clearTimeout(doSearch);
    } else if (searchVal === "") {
      setSurveysClone(surveys);
      setProcess(false);
    } else {
      setProcess(false);
    }
    // eslint-disable-next-line
  }, [searchVal]);

  useEffect(() => {
    let doCategory = setTimeout(() => {
      let surveysCloneCopy =
        category !== "*" ? surveys.filter((s) => s.type === category) : surveys;
      setSurveysClone(surveysCloneCopy);
      setProcess(false);
    }, 1000);
    return () => clearTimeout(doCategory);
    // eslint-disable-next-line
  }, [category]);

  return (
    <div id="home-page">
      {surveysClone !== null && !loading && (
        <Card>
          <div className="inline-filters">
            <div className="options">
              <Space.Compact direction="vertical" style={{ width: "100%" }}>
                <label htmlFor="search-input">Buscar</label>
                <Input
                  id="search-input"
                  onChange={({ target: { value } }) => {
                    setProcess(true);
                    setSearchVal(value.toLowerCase());
                  }}
                  value={searchVal}
                  placeholder="Ao menos 3 letras"
                />
              </Space.Compact>
            </div>
            <div className="options">
              <Space.Compact direction="vertical" style={{ width: "100%" }}>
                <label htmlFor="category-input">Categoria</label>
                <Select
                  id="category-input"
                  onChange={(value) => {
                    setProcess(true);
                    setCategory(value);
                  }}
                  value={category}
                  options={[
                    { value: "*", label: "Todas" },
                    { value: "ax", label: "(AX) After Experience" },
                    { value: "bx", label: "(BX) Before Experience" },
                    { value: "ex", label: "(EX) Employee Experience" },
                    { value: "cx", label: "(CX) Customer Experience" },
                    {
                      value: "cxc",
                      label: "(CXC) Customer Experience Consulting",
                    },
                    {
                      value: "slx",
                      label: "(SLX) Social Listening Experience",
                    },
                    { value: "ah", label: "(AH) Adhoc" },
                  ]}
                />
              </Space.Compact>
            </div>
          </div>
          <Divider />
          {process && (
            <div className="in-search-brain">
              <strong>Buscando...</strong>
            </div>
          )}
          <div className="content">
            {!process &&
              surveysClone.map((dash, i) => {
                return (
                  <Card.Grid
                    key={`dash-item-${i}`}
                    className="item"
                    style={{
                      padding: "10px",
                    }}
                  >
                    <a href={dash.url}>
                      <div className="icon">
                        <img src={icons[dash.type].icon} alt="Icon" />
                      </div>
                      <div className="description">
                        <div className="dash-name">{dash.label}</div>
                        <div className="dash-type">
                          {icons[dash.type].label}
                        </div>
                      </div>
                    </a>
                    {token.role === "admin" && (
                      <div className="more-options">
                        <Popover
                          content={
                            <Menu
                              style={{ padding: 0 }}
                              onClick={({ key }) => {
                                if (key === "open") {
                                  window.open(
                                    "https://inovyo.us.qlikcloud.com/sense/app/" +
                                      dash.publish,
                                    "_blank"
                                  );
                                } else if (key === "edit") {
                                  window.open(
                                    "https://inovyo.us.qlikcloud.com/sense/app/" +
                                      dash.edit,
                                    "_blank"
                                  );
                                } else if (key === "publish") {
                                  dispatch(publishApp(dash.publish, dash.edit));
                                } else if (key === "reload") {
                                  setOpenReload(!openReload);
                                  dispatch(reloadApp(dash.publish));
                                }
                              }}
                              mode="inline"
                              items={[
                                {
                                  label: (
                                    <>
                                      Abrir{" "}
                                      <strong>
                                        <em
                                          style={{
                                            color: "#aaa",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (Produção)
                                        </em>
                                      </strong>
                                    </>
                                  ),
                                  key: "open",
                                  icon: <ExportOutlined />,
                                },
                                {
                                  label: (
                                    <>
                                      Editar{" "}
                                      <strong>
                                        <em
                                          style={{
                                            color: "#aaa",
                                            fontSize: "10px",
                                          }}
                                        >
                                          (Desenvolvimento)
                                        </em>
                                      </strong>
                                    </>
                                  ),
                                  key: "edit",
                                  icon: <EditOutlined />,
                                },
                                {
                                  label: "Publicar",
                                  key: "publish",
                                  icon: <SendOutlined />,
                                },
                                {
                                  label: "Recarregar",
                                  key: "reload",
                                  icon: <ReloadOutlined />,
                                },
                              ]}
                            />
                          }
                          trigger="click"
                          placement="left"
                          style={{ padding: 0 }}
                        >
                          <MoreOutlined style={{ fontSize: 20 }} />
                        </Popover>
                      </div>
                    )}
                  </Card.Grid>
                );
              })}

            {Array.isArray(surveysClone) &&
              surveysClone.length <= 0 &&
              !loading &&
              !process && (
                <Empty
                  description="Nenhuma pesquisa listada"
                  image={<AppstoreOutlined />}
                  imageStyle={{ fontSize: "80px", color: "#aaa" }}
                  style={{ margin: "auto" }}
                />
              )}
          </div>
        </Card>
      )}
      <Modal
        open={openReload}
        cancelText="Cancelar"
        closable={false}
        onCancel={() => {
          setOpenReload(!openReload);
          dispatch(setReloadRedux(false));
          dispatch(setQlikRedux(null));
        }}
        onOk={() => {
          setOpenReload(!openReload);
          dispatch(setReloadRedux(false));
          dispatch(setQlikRedux(null));
        }}
        title={
          reload ? (
            "Processando..."
          ) : (
            <>
              Recarga de dados{" "}
              {qlik !== null && (
                <Badge
                  count={badgeReload[qlik.status].label}
                  color={badgeReload[qlik.status].color}
                />
              )}
            </>
          )
        }
      >
        <div
          style={{
            height: "400px",
            overflow: "hidden auto",
            fontSize: "12px",
            color: "#666",
          }}
        >
          {qlik !== null ? (
            <Interweave content={qlik?.log} />
          ) : (
            "Aguarde! Esta ação pode levar segundos ou alguns poucos minutos..."
          )}
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
