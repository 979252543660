import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "apps",
  initialState: {
    appsRedux: null,
    tbLoadingRedux: false, //loading das tabelas
  },
  reducers: {
    setAppsRedux(state, { payload }) {
      state.appsRedux = payload;
    },
    setTbLoadingRedux(state, { payload }) {
      state.tbLoadingRedux = payload;
    },
  },
});

export const { setAppsRedux, setTbLoadingRedux } = slice.actions;

export const appsRedux = (state) => state.apps.appsRedux;
export const tbLoadingRedux = (state) => state.apps.tbLoadingRedux;

export default slice.reducer;
