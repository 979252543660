import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography, Table } from "antd";

//redux
import { tbLoadingRedux, appsRedux } from "../../redux/apps/apps.slice";
import { fetchApps } from "../../redux/apps/apps.actions";

//providers
import { Context } from "../../providers/AuthContext";

const AppsPage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  //states
  const [resultsOnPage, setResultsOnPage] = useState(10);

  //redux state
  const tableLoading = useSelector(tbLoadingRedux);
  const apps = useSelector(appsRedux);

  useEffect(() => {
    dispatch(fetchApps(token.user));
    // eslint-disable-next-line
  }, []);

  const tableChanges = (e) => {
    if (e.pageSize !== resultsOnPage) {
      setResultsOnPage(e.pageSize);
    }
  };

  return (
    <Card>
      <div id="apps-page">
        <Title level={4}>Aplicativos (Apps)</Title>
        <Table
          responsive={true}
          columns={[
            {
              title: "Nome",
              dataIndex: "name",
              sorter: (a, b) => a.name - b.name,
            },
            {
              title: "Mashup ID",
              dataIndex: "mashup_id",
              sorter: (a, b) => a.mashup_id - b.mashup_id,
            },
            {
              title: "ID",
              dataIndex: "edition_id",
              sorter: (a, b) => a.edition_id - b.edition_id,
            },
            {
              title: "ID DEV",
              dataIndex: "publish_id",
              sorter: (a, b) => a.publish_id - b.publish_id,
            },
            {
              title: "Modificado em",
              dataIndex: "modified_at",
              sorter: (a, b) => a.modified_at - b.modified_at,
            },
            {
              title: "Última atualização",
              dataIndex: "last_reload",
              sorter: (a, b) => a.last_reload - b.last_reload,
            },
          ]}
          dataSource={apps}
          pagination={{
            pageSize: resultsOnPage,
            showSizeChanger: true,
            size: "small",
          }}
          onChange={(e) => tableChanges(e)}
          scroll={{ y: 380 }}
          loading={tableLoading}
        />
      </div>
    </Card>
  );
};

export default AppsPage;
