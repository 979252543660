import React, { useContext, useState } from "react";
import { Menu, Badge, Drawer, Button } from "antd";
import { Link } from "react-router-dom";
import {
  SettingOutlined,
  BellOutlined,
  BellFilled,
  MenuOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

//styles
import "../../assets/styles/components/app/header-app.css";

//images
import LogoBrain from "../../assets/images/brain-inovyo.svg";

//provider
import { Context } from "../../providers/AuthContext";

//redux
import {
  notificationsRedux,
  setOpenNotificationsRedux,
  windowWHRedux,
} from "../../redux/application/app.slice";

const HeaderApp = () => {
  const dispatch = useDispatch();
  const route = window.location.pathname;
  const { authenticated, token } = useContext(Context);

  const [openMenu, setOpenMenu] = useState(false);
  const notifications = useSelector(notificationsRedux);
  const widthScreen = useSelector(windowWHRedux);
  const w = 750;

  const MenuButtons = () => (
    <div className="menu-small">
      {token.role !== "ftp" && (
        <Link to="/" className={route === "/" ? "selected" : ""}>
          Dashboards
        </Link>
      )}

      {["admin", "customersuccess", "sale"].includes(token.role) && (
        <Link
          to="/users"
          className={["/users", "/new-user"].includes(route) ? "selected" : ""}
        >
          Usuários
        </Link>
      )}

      {token.role !== "demo" && (
        <>
          <Link
            to="/bases"
            className={
              ["/bases", "/send-bases"].includes(route) ? "selected" : ""
            }
          >
            Bases
          </Link>
          <a href="/surveys" className={route === "/surveys" ? "selected" : ""}>
            Pesquisas
          </a>
        </>
      )}

      {token.role === "admin" && (
        <>
          <Link to="/apps" className={route === "/apps" ? "selected" : ""}>
            Apps
          </Link>
          <Link to="/spaces" className={route === "/spaces" ? "selected" : ""}>
            Espaços
          </Link>
          <Link
            to="https://inovyo.us.qlikcloud.com/"
            target="_blank"
            rel="noreferrer"
          >
            Qlik
          </Link>
        </>
      )}

      {widthScreen[0] <= w && (
        <>
          <Link
            to="/settings"
            className={route === "/settings" ? "selected" : ""}
          >
            Configurações
          </Link>
          <Link href="/logout">Sair</Link>
        </>
      )}
    </div>
  );

  return (
    <>
      {authenticated && (
        <header id="header-inovyo">
          <div className="logo">
            <a href="/" title="Home">
              <img src={LogoBrain} alt="LogoBrain" />
            </a>
          </div>

          {token.role !== "customersuccess" && (
            <div className="magic-button">
              <Button type="default">+ Criar</Button>
            </div>
          )}

          {widthScreen[0] > w && <MenuButtons />}
          <div className="options">
            <div className="notifcation-inovyo">
              <Badge
                dot={notifications.length > 0}
                onClick={() => dispatch(setOpenNotificationsRedux())}
              >
                {notifications.length > 0 ? (
                  <BellFilled style={{ fontSize: "21px" }} />
                ) : (
                  <BellOutlined style={{ fontSize: "21px" }} />
                )}
              </Badge>
            </div>
            {widthScreen[0] <= w && (
              <div
                className="menu-inovyo"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <MenuOutlined />
              </div>
            )}
            {widthScreen[0] > w && (
              <Menu
                onClick={({ key }) => {
                  window.location.href = "/" + key;
                  return;
                }}
                style={{ height: "60px" }}
                mode="horizontal"
                items={[
                  {
                    label: "Conta",
                    key: "SubMenu",
                    icon: <SettingOutlined />,
                    children: [
                      {
                        label: "Configurações",
                        key: "account",
                      },
                      {
                        label: "Sair",
                        key: "logout",
                      },
                    ],
                  },
                ]}
              />
            )}
          </div>
          <Drawer
            title="Inovyo"
            placement="right"
            closable={true}
            open={openMenu}
            onClose={() => setOpenMenu(!openMenu)}
            width={320}
            id="menu-small-drawer"
          >
            <MenuButtons />
          </Drawer>
        </header>
      )}
    </>
  );
};

export default HeaderApp;
