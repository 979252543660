import React, { useEffect, useContext } from "react";
import { Card, Typography, Form, Col, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";
//import { toast } from "react-toastify";

//redux
import { accessAPIRX } from "../../redux/settings/settings.slice";
import { getAcessos } from "../../redux/settings/settings.actions";

//components
import TabNavigation from "./components/TabNavigation";
import { Context } from "../../providers/AuthContext";

const IntegrationPage = () => {
  const { Title, Text, Link } = Typography;
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  const accessAPI = useSelector(accessAPIRX);

  useEffect(() => {
    dispatch(getAcessos());
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <TabNavigation>
        <div id="integration-page">
          {accessAPI !== null && (
            <>
              <Title level={4}>Informações da Conta</Title>
              <Text>
                Utilize os dados abaixo para realizar integração com nossa
                solução.{" "}
                <Link
                  underline
                  href="https://api.inovyo.com/new/docs"
                  target="_blank"
                >
                  <u>Clique aqui</u>
                </Link>{" "}
                para acessar a documentação da API.
              </Text>
              <Form
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                style={{ marginTop: 24 }}
              >
                <Row gutter={24}>
                  <Col xs={24} sm={24}>
                    <strong>Cliente</strong>
                    <p style={{ color: "#a3a3a3" }}>{token.user}</p>
                  </Col>
                  <Col xs={24} sm={24}>
                    <strong>Token</strong>
                    <p style={{ color: "#a3a3a3" }}>{accessAPI.api_token}</p>
                  </Col>
                  <Col xs={24} sm={24}>
                    <strong>Secret Token</strong>
                    <p style={{ color: "#a3a3a3" }}>{accessAPI.api_secret}</p>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </div>
      </TabNavigation>
    </Card>
  );
};

export default IntegrationPage;
