import { toast } from "react-toastify";

//redux
import {
  fetchHubAPI,
  qlikConnectionAPI,
  reloadAppAPI,
  publishAppAPI,
} from "./general.service";
import { setSurveysRedux, setReloadRedux, setQlikRedux } from "./general.slice";
import { setLoadingRedux } from "../application/app.slice";

export const fetchHub = () => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));

    //Não faz nada
    qlikConnectionAPI().then().catch();

    fetchHubAPI()
      .then(({ data }) => {
        dispatch(setSurveysRedux(data.length > 0 ? data : []));
      })
      .catch(() => {
        toast.error("Falha ao carregar pesquisa!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingRedux(false));
        }, 1000);
      });
  };
};

export const reloadApp = (publish_id) => {
  return (dispatch) => {
    dispatch(setReloadRedux(true));
    reloadAppAPI(publish_id)
      .then(({ data }) => {
        dispatch(setReloadRedux(false));
        dispatch(setQlikRedux(data));
      })
      .catch((error) => {
        let errorMessage = "";
        let errorTag = "";

        if (error.status === 409 || error.status === 404) {
          errorMessage = error.response.data.detail.log;
          errorTag = error.response.data.detail.status;
        } else {
          errorMessage = "Falha ao carregar dados. Tente novamente!";
          errorTag = "ERROR500";
        }

        dispatch(setReloadRedux(false));
        dispatch(setQlikRedux({ status: errorTag, log: errorMessage }));
        dispatch(setReloadRedux(false));
      });
  };
};

export const publishApp = (publish_id, edition_id) => {
  toast.info("Publicando, aguarde....");

  return () => {
    publishAppAPI(publish_id, edition_id)
      .then(() => {
        toast.success("Publicação feita com sucesso!");
      })
      .catch(() => {
        toast.error("Falha ao publicar aplicativo!");
      });
  };
};
