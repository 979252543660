/**
 * Cria um session se ele não existir. O padrão é vazio, mas
 * ele pode receber um valor padrão desde que seja informado
 * @param {string} name - nome do session
 * @param {string} value - informação a ser armazenada
 * @param {boolean} isJson - se verdadeiro, transforma um objeto em string
 */
export const createCookieIfNotExist = (name, value, expire) => {
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie.indexOf(name) <= -1) {
    const d = new Date();
    d.setTime(d.getTime() + expire * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    let cookieData = `${name}=${value};${expires};path=/`;
    document.cookie = cookieData;
  }
};

/**
 * Cria um cookie
 * @param {string} name - nome do cookie
 * @param {string} value - informação a ser armazenada
 * @param {number} expire - tempo de vida do cookie em dias
 */
export const setCookie = (name, value, expire) => {
  const d = new Date();
  d.setTime(d.getTime() + expire * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  let cookieData = `${name}=${value};${expires};path=/`;
  document.cookie = cookieData;
};

/**
 * Pega o valor de um cookie e retorna se ele existir
 * @param {string} name - nome do cookie
 * @param {boolean} isJson - se verdadeiro, transforma uma string em objeto
 * @returns {string|object}
 */
export const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split("; ");
  for (let i = 0; i < ca.length; i++) {
    let cookie = ca[i];
    if (cookie.indexOf(name) >= 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

/**
 * Exclui um cookie específico
 * @param {string} name
 */
export const deleteCookie = (name) => {
  let expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
  let cookieData = `${name}=;${expires};path=/`;
  document.cookie = cookieData;
};

/**
 * Apaga todos os cookies do navegador
 */
export const clearCookies = () => {
  let expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split("; ");
  for (let i = 0; i < ca.length; i++) {
    let cookie = ca[i].split("=")[0];
    let cookieData = `${cookie}=;${expires};path=/`;
    document.cookie = cookieData;
  }
};
