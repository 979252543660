import HistoricBasePage from "../../pages/bases/historic";
import SendBasePage from "../../pages/bases/send";

//BASES
export const baseRoutes = () => {
  return [
    { path: "/bases", page: HistoricBasePage, auth: true },
    { path: "/send-bases", page: SendBasePage, auth: true },
  ];
};
