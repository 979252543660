import { fetchAppsAPI } from "./apps.service";

//redux
import { setAppsRedux, setTbLoadingRedux } from "./apps.slice";

export const fetchApps = (userId) => {
  return (dispatch) => {
    dispatch(setTbLoadingRedux(true));

    fetchAppsAPI()
      .then(({ data }) => {
        dispatch(setAppsRedux(data));
      })
      .catch(() => {})
      .finally(() => {
        setTimeout(() => {
          dispatch(setTbLoadingRedux(false));
        }, 1000);
      });
  };
};
