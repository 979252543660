import { toast } from "react-toastify";
import { setCookie } from "../../utils/Cookie";

//redux
import { doLoginAPI } from "./auth.service";
import { setLoadingRedux } from "../application/app.slice";

export const doLogin = (dataLogin) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    doLoginAPI(dataLogin)
      .then((jsonAPI) => {
        toast.success("Acesso autorizado", { autoClose: 1000 });
        setCookie("TOKEN", jsonAPI.jwtToken, 1);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch(() => {
        toast.error("Usuário não encontrado!", { autoClose: 1000 });
        dispatch(setLoadingRedux(false));
      });
  };
};
