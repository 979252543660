import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "users",
  initialState: {
    users: null,
    tbLoading: false, //loading das tabelas
  },
  reducers: {
    setTbLoadingRedux(state, { payload }) {
      state.tbLoading = payload;
    },
    setUsersRedux(state, { payload }) {
      state.users = payload;
    },
    deleteUserAndReorder(state, { payload }) {
      const removedUser = state.users.filter((usr) => usr.id !== payload);
      state.users = removedUser;
    },
  },
});

export const { setTbLoadingRedux, setUsersRedux, deleteUserAndReorder } =
  slice.actions;

export const tbLoadingRedux = (state) => state.users.tbLoading;
export const usersRedux = (state) => state.users.users;

export default slice.reducer;
