import React from "react";

//styles
import "../../assets/styles/components/app/content-app.css";

const ContentApp = ({ children }) => {
  return <section id="content-inovyo">{children}</section>;
};

export default ContentApp;
