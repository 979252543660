import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

if (window.location.hostname.indexOf("inovyo") >= 0) {
  console.log = function () {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);