import ReactLoading from "react-loading";

//styles
import "../../assets/styles/components/ui/loading.css";

//images
import LogoBrain from "../../assets/images/brain-inovyo.svg";

const Loading = () => {
  return (
    <div id="loading-page">
      <img src={LogoBrain} alt="LogoBrain" />
      <ReactLoading type="spinningBubbles" color="#9A2376" height="150px" width="150px" />
    </div>
  );
};

export default Loading;
