import React, { useState, useEffect, useContext } from "react";
import { Card, Typography, Table, Tag, Popover, Menu, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  DeleteFilled,
  DownloadOutlined,
  MoreOutlined,
} from "@ant-design/icons";

//redux
import { tbLoadingRedux, basesRedux } from "../../redux/bases/bases.slice";
import {
  fetchBases,
  downloadBaseExcel,
  deleteBase,
} from "../../redux/bases/bases.actions";

//provide
import { Context } from "../../providers/AuthContext";

const HistoricBasePage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  //states
  const [resultsOnPage, setResultsOnPage] = useState(10);

  //redux state
  const tableLoading = useSelector(tbLoadingRedux);
  const bases = useSelector(basesRedux);

  useEffect(() => {
    dispatch(fetchBases(token.user));
    // eslint-disable-next-line
  }, []);

  const tableChanges = (e) => {
    if (e.pageSize !== resultsOnPage) {
      setResultsOnPage(e.pageSize);
    }
  };

  const deleteFile = (row) => {
    toast.info("Excluindo base...");
    const { key, filename } = row;
    dispatch(
      deleteBase({
        preffix: token.user,
        filename,
        key,
      })
    );
  };
  const downloadFile = (row) => {
    toast.info("Preparando o seu download...");
    const { surveyid, user: userId, filename } = row;
    dispatch(
      downloadBaseExcel({
        id: token.id,
        preffix: token.user,
        filename,
        fileOfUser: userId,
        survey: surveyid,
      })
    );
  };

  return (
    <Card>
      <div id="historic-base-page">
        <Title level={4}>Bases</Title>
        <Button
          href="/send-bases"
          type="primary"
          style={{
            position: "absolute",
            zIndex: 10,
            top: "20px",
            left: "100px",
          }}
        >
          Enviar Base
        </Button>
        <Table
          responsive={true}
          columns={[
            {
              title: "Data de Envio",
              dataIndex: "createdat",
              sorter: (a, b) => a.timestamp - b.timestamp,
              defaultSortOrder: "descend",
              width: 130,
            },
            {
              title: "Ponto de Contato",
              dataIndex: "survey",
              sorter: (a, b) => {
                return a.survey < b.survey ? -1 : a.survey > b.survey ? 1 : 0;
              },
              width: 150,
            },
            {
              title: "Arquivo",
              dataIndex: "filename",
              sorter: (a, b) => {
                return a.filename < b.filename
                  ? -1
                  : a.filename > b.filename
                  ? 1
                  : 0;
              },
              width: 150,
            },
            {
              title: "Qtd. de Contatos",
              dataIndex: "contacts",
              sorter: (a, b) => a.contacts - b.contacts,
              width: 100,
            },
            {
              title: "Status",
              dataIndex: "status",
              render: (item) => {
                let color =
                  item === "waiting"
                    ? "#999999"
                    : item === "processing"
                    ? "#ACE8FF"
                    : item === "success"
                    ? "#8FCA8F"
                    : "#D1807D";

                return (
                  <Tag color={color} key={item}>
                    {item === "waiting"
                      ? "Na fila"
                      : item === "processing"
                      ? "Processando"
                      : item === "success"
                      ? "Sucesso"
                      : "Falha"}
                  </Tag>
                );
              },
              sorter: (a, b) => a.status - b.status,
              width: 100,
              key: 5,
            },
            {
              title: "Ações",
              fixed: "right",
              width: 70,
              align: "right",
              render: (row) => {
                return (
                  <>
                    <Popover
                      content={
                        <Menu
                          onClick={({ key }) => {
                            if (key === "download-base") {
                              downloadFile(row);
                            } else if (key === "delete-base") {
                              deleteFile();
                            }
                          }}
                          mode="inline"
                          items={[
                            {
                              label: "Download da base",
                              key: "download-base",
                              icon: <DownloadOutlined />,
                            },
                            {
                              label: "Excluir Base",
                              key: "delete-base",
                              icon: <DeleteFilled />,
                            },
                          ]}
                        />
                      }
                      trigger="click"
                      placement="left"
                    >
                      <MoreOutlined style={{ fontSize: 20 }} />
                    </Popover>
                  </>
                );
              },
            },
          ]}
          dataSource={bases}
          pagination={{
            pageSize: resultsOnPage,
            showSizeChanger: true,
            size: "small",
          }}
          onChange={(e) => tableChanges(e)}
          scroll={{ y: 380 }}
          loading={tableLoading}
        />
      </div>
      {/* <TabNavigation>
      </TabNavigation> */}
    </Card>
  );
};

export default HistoricBasePage;
