import axios from "axios";
import { getCookie } from "../utils/Cookie";

export const token = (() => {
  return getCookie("TOKEN");
})();

export const authorization = (() => {
  return {
    authorization: `Bearer ${token !== "" ? token : ""}`,
  };
})();

/**
 * Retorna o header com dados de autenticação
 * @param {string} contentHeader.authorization - Autorização de segurança
 * @returns {Object}
 */
export const headers = (() => {
  let contentHeader = {};

  if (token !== "") {
    contentHeader.authorization = `Bearer ${token !== "" ? token : ""}`;
  }
  return contentHeader;
})();

export default axios.create({
  baseURL: "https://account2.inovyo.com/api/",
});
