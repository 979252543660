import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "spaces",
  initialState: {
    spacesRedux: null,
  },
  reducers: {
    setSpacesRedux(state, { payload }) {
      state.spacesRedux = payload;
    },
  },
});

export const { setSpacesRedux } = slice.actions;

export const spacesReduxRedux = (state) => state.spaces.spacesRedux;

export default slice.reducer;
