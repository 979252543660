import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "app",
  initialState: {
    loadingRedux: false,
    updateRedux: false,
    windowWHRedux: [window.innerWidth, window.innerHeight],
    openNotificationsRedux: false,
    notificationsRedux: [],
  },
  reducers: {
    setLoadingRedux(state, { payload }) {
      state.loadingRedux = payload;
    },
    setUpdateRedux(state, { payload }) {
      state.updateRedux = payload;
    },
    setWindowWHRedux(state) {
      state.windowWHRedux = [window.innerWidth, window.innerHeight];
    },
    setOpenNotificationsRedux(state) {
      state.openNotificationsRedux = !state.openNotificationsRedux;
    },
    setNotificationsRedux(state, { payload }) {
      state.notificationsRedux = payload;
    },
  },
});

export const {
  setLoadingRedux,
  setUpdateRedux,
  setWindowWHRedux,
  setOpenNotificationsRedux,
  setNotificationsRedux,
} = slice.actions;

export const loadingRedux = (state) => state.app.loadingRedux;
export const updateRedux = (state) => state.app.updateRedux;
export const windowWHRedux = (state) => state.app.windowWHRedux;
export const notificationsRedux = (state) => state.app.notificationsRedux;
export const openNotificationsRedux = (state) =>
  state.app.openNotificationsRedux;

export default slice.reducer;
