import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import moment from "moment";

//providers
import { AuthProvider } from "./providers/AuthContext";

//redux
import store from "./redux";

//routes
import AppRoutes from "./routes";

//styles
import "./assets/styles/global.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  moment.locale("pt-br");

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#9a2376",
            },
          }}
        >
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
