import React from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";

//redux
import { windowWHRedux } from "../../../redux/application/app.slice";

const TabNavigation = ({ children }) => {
  const dimentionsScreen = useSelector(windowWHRedux);

  const changeTabPosition = (tabId) => {
    console.log(tabId)
    window.location.pathname =
      tabId === "account" ? "/account" : "/integration";
    return;
  };

  return (
    <Tabs
      tabPosition={dimentionsScreen[0] >= 768 ? "left" : "top"}
      activeKey={
        window.location.href.indexOf("account") >= 0
          ? "account"
          : "integration"
      }
      onTabClick={(tabId) => changeTabPosition(tabId)}
      items={[
        {
          label: `Conta`,
          key: "account",
          children,
        },
        {
          label: `Integração`,
          key: "integration",
          children,
        },
      ]}
    />
  );
};

export default TabNavigation;
