import { configureStore } from "@reduxjs/toolkit";

import app from "./application/app.slice";
import bases from "./bases/bases.slice";
import general from "./general/general.slice";
import users from "./users/users.slice";
import apps from "./apps/apps.slice";
import spaces from "./spaces/spaces.slice";
import settings from "./settings/settings.slice";

export default configureStore({
  reducer: {
    app,
    general,
    bases,
    users,
    apps,
    spaces,
    settings,
  },
});
