import LoginPage from "../../pages/auth/login";
import LogoutPage from "../../pages/auth/logout";

//AUTH
export const authRoutes = () => {
  return [
    { path: "/login", page: LoginPage },
    { path: "/logout", page: LogoutPage },
  ];
};
