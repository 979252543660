import React, { useEffect } from "react";
import ReactLoading from "react-loading";

//utils
import { clearStorages } from "../../utils/Storage";
import { clearSessions } from "../../utils/Session";
import { clearCookies } from "../../utils/Cookie";

//styles
import "../../assets/styles/auth/logout.css";

const LogoutPage = () => {
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    //se falhar, reconceta com o qlik
    fetch("https://inovyo.us.qlikcloud.com/logout", {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      credentials: "include",
    })
      .then(() => {})
      .catch(() => {});

    setTimeout(() => {
      clearStorages();
      clearSessions();
      clearCookies();
      window.location.href = "/login";
      return;
    }, 1000);
  }, []);

  return (
    <div id="logout-page">
      <div>
        Saindo...
        <br />
        <ReactLoading type="bubbles" color="#000000" />
      </div>
    </div>
  );
};

export default LogoutPage;
