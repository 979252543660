import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "settings",
  initialState: {
    accountDetails: null,
    accessAPI: null,
  },
  reducers: {
    setAccountDetailsRedux(state, { payload }) {
      state.accountDetails = payload;
    },
    setAccessAPIRedux(state, { payload }) {
      state.accessAPI = payload;
    },
  },
});

export const { setAccountDetailsRedux, setAccessAPIRedux } = slice.actions;

export const accountDetailsRX = (state) => state.settings.accountDetails;
export const accessAPIRX = (state) => state.settings.accessAPI;

export default slice.reducer;
