import api, { headers } from "../../services/api";

export const fetchUsersAPI = async () => {
  return api.get("usuarios", { headers });
};

export const fetchAppsAPI = async () => {
  return api.get("apps", { headers });
};

export const deleteUserAPI = async (userId) => {
  return api.delete(`deletar-usuario/${userId}`, { headers });
};

export const createUserAPI = async (dataForm) => {
  return api.post(`novo-usuario`, dataForm, { headers });
};
