import React from "react";
import { Card, Typography } from "antd";

const SpacesPage = () => {
  const { Title } = Typography;

  return (
    <Card>
      <div id="spaces-page">
        <Title level={4}>Espaços</Title>
      </div>
    </Card>
  );
};

export default SpacesPage;
