import React from "react";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, List } from "antd";

//components
import ContentApp from "./ContentApp";
import HeaderApp from "./HeaderApp";
import FooterApp from "./FooterApp";

//ui
import Loading from "../ui/Loading";

//redux
import {
  loadingRedux,
  openNotificationsRedux,
  setOpenNotificationsRedux,
  notificationsRedux,
} from "../../redux/application/app.slice";

const LayoutApp = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  let loading = useSelector(loadingRedux);
  let notifications = useSelector(notificationsRedux);
  let openNotifications = useSelector(openNotificationsRedux);

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-center"
        closeOnClick
        pauseOnHover
        autoClose={2000}
      />
      <HeaderApp />
      <ContentApp>{children}</ContentApp>
      <FooterApp />
      <Drawer
        title="Notificações"
        placement="right"
        closable={true}
        open={openNotifications}
        onClose={() => dispatch(setOpenNotificationsRedux())}
        width={320}
      >
        <List
          size="small"
          itemLayout="vertical"
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

export default LayoutApp;
