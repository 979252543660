//redux
import {
  setUsersRedux,
  setTbLoadingRedux,
  deleteUserAndReorder,
} from "./users.slice";
import { setLoadingRedux } from "../application/app.slice";
import { fetchUsersAPI, deleteUserAPI, createUserAPI } from "./users.service";
import { toast } from "react-toastify";

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(setTbLoadingRedux(true));

    fetchUsersAPI()
      .then(({ data }) => {
        dispatch(setUsersRedux(data.users));
      })
      .catch(() => {
        toast.error("Problema ao exibir usuários. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setTbLoadingRedux(false));
        }, 1000);
      });
  };
};

export const deleteUserById = (userId) => {
  return (dispatch) => {
    dispatch(setTbLoadingRedux(true));

    deleteUserAPI(userId)
      .then(() => {
        dispatch(deleteUserAndReorder(userId));
        setTimeout(() => {
          toast.success("Usuário removido com sucesso.");
          setTimeout(() => {
            window.location.href = "/users";
          }, 1000);
        }, 1000);
      })
      .catch(() => {
        toast.error("Problema ao remover usuário. Tente novamente!");
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setTbLoadingRedux(false));
        }, 1000);
      });
  };
};

export const createUser = (dataForm) => {
  return (dispatch) => {
    dispatch(setLoadingRedux(true));
    createUserAPI(dataForm)
      .then(() => {
        toast.success("Usuário criado com sucesso.");
        setTimeout(() => {
          window.location.href = "/users";
        }, 1000);
      })
      .catch((error) => {
        if (error?.response && error.response.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error("Problema ao criar usuário. Tente novamente!");
        }
        dispatch(setLoadingRedux(false));
      });
  };
};
