import api, { headers } from "../../services/api";

export const fetchHubAPI = async () => {
  return api.get("hub", { headers });
};

export const reloadAppAPI = async (publish_id) => {
  api.defaults.timeout = 300000;
  return api.post("reload-app", { publish_id }, { headers });
};

export const publishAppAPI = async (publish_id, edition_id) => {
  return api.post("republish", { publish_id, edition_id }, { headers });
};

export const qlikConnectionAPI = async (data) => {
  return api
    .get("/qlik-session", { headers }) //conexão inovyo
    .then(({ data: authToken }) => {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", "Bearer " + authToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "qlik-web-integration-id",
        "ITfIUfl_JcXKFLRc7GnORJ9iUWHWj5lw"
      );

      fetch("https://inovyo.us.qlikcloud.com/api/v1/users/me", {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        credentials: "include",
      })
        .then((res) => {
          if (res.status !== 200) {
            throw Object.assign(new Error("Auth Fail"), { code: 401 });
          }
        })
        .catch(() => {
          //se falhar, reconceta com o qlik
          fetch("https://inovyo.us.qlikcloud.com/login/jwt-session", {
            method: "POST",
            headers: myHeaders,
            mode: "cors",
            credentials: "include",
          })
            .then(() => {})
            .catch(() => {});
        });
    })
    .catch(() => {});
};
