import React from "react";

import "../../assets/styles/general/notfound404.css";

const Page404 = () => {
  return (
    <div id="notfound-page">
      <span>404</span>
      Página não encontrada
    </div>
  );
};

export default Page404;
