import AccountPage from "../../pages/settings/account";
import IntegrationPage from "../../pages/settings/integration";

//BASES
export const settingsRoutes = () => {
  return [
    { path: "/account", page: AccountPage, auth: true },
    { path: "/integration", page: IntegrationPage, auth: true },
  ];
};
