import UsersPage from "../../pages/users/users";
import NewUserPage from "../../pages/users/new-user";

//USUÁRIOS
export const userRoutes = () => {
  return [
    { path: "/users", page: UsersPage, auth: true },
    { path: "/new-user", page: NewUserPage, auth: true },
  ];
};
