import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "antd";

//providers
import { Context } from "../providers/AuthContext";

//compoenents
import LayoutApp from "../components/app/LayoutApp";

//redux
import { setWindowWHRedux } from "../redux/application/app.slice";

//routes
import { listRoutes, activeRoute } from "./modules";

//images
import LogoBlocked from "../assets/images/brain-inovyo-blocked.svg";

const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let match = window.location.pathname;
  let { loading, authenticated, token } = useContext(Context);
  const allRoutes = listRoutes();
  const { auth } = activeRoute();

  const calcResize = () => {
    let funcSetResize = null;
    window.addEventListener("resize", () => {
      clearTimeout(funcSetResize);
      funcSetResize = setTimeout(() => {
        dispatch(setWindowWHRedux());
      }, 300);
    });
  };

  const PageAccessDenied = () => (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontSize: "20px",
        gap: 10,
      }}
    >
      <img src={LogoBlocked} width={100} alt="LogoBlocked" />
      <span>Acesso Negado!</span>
      <Button onClick={() => navigate(-1)}>Voltar à página anterior</Button>
    </div>
  );

  const PageLoad = () => (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Carregando...
    </div>
  );

  useEffect(() => {
    if (!window.navigator.onLine) {
      toast.error("Você está sem internet!");
    }
    window.addEventListener("online", () => {
      toast.success("Você está com internet!");
    });
    window.addEventListener("offline", () => {
      toast.error("Você está sem internet!");
    });

    calcResize();
    return calcResize();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <PageLoad />;
  } else {
    /* Se auth existir vindo do componente de rota,
      significa que é uma rota privada e deve validar todos
      os acessos antes do usuário visualizar */
    if (auth) {
      /* Só tem acesso as rotas privadas se autenticado */
      if (authenticated) {
        /* Quando autenticado e estiver na rota /login
         * será redirecionado para /home */
        if (match === "/login") {
          window.location.href = "/";
          return;
        }

        if (match === "/" && token.role === "ftp") {
          window.location.href = "/bases";
          return;
        }

        if (
          ["/users", "/new-user"].includes(match) &&
          !["admin", "customersuccess", "sale"].includes(token.role)
        ) {
          return <PageAccessDenied />;
        }

        if (
          ["/users", "/new-user", "/bases", "/send-bases", "/surveys"].includes(
            match
          ) &&
          token.role === "demo"
        ) {
          return <PageAccessDenied />;
        }

        if (["/users", "/new-user"].includes(match) && token.role === "ftp") {
          return <PageAccessDenied />;
        }

        if (["/apps", "/spaces"].includes(match) && token.role !== "admin") {
          return <PageAccessDenied />;
        }
      } else {
        window.location.href = "/login";
        return;
      }
    }
  }

  return (
    <LayoutApp>
      <Routes>
        {allRoutes.map((e, index) => (
          <Route path={e.path} key={"routes" + index} element={<e.page />} />
        ))}
      </Routes>
    </LayoutApp>
  );
};

export default AppRoutes;
