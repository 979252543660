import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Card,
  Typography,
  Table,
  Tooltip,
  Popconfirm,
  Button,
  Input,
  Space,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

//redux
import { tbLoadingRedux, usersRedux } from "../../redux/users/users.slice";
import { fetchUsers, deleteUserById } from "../../redux/users/users.actions";

//provider
import { Context } from "../../providers/AuthContext";

const UsersPage = () => {
  const { Title } = Typography;
  const dispatch = useDispatch();
  const { token } = useContext(Context);

  //states
  const [resultsOnPage, setResultsOnPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  //redux state
  const tableLoading = useSelector(tbLoadingRedux);
  const users = useSelector(usersRedux);

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line
  }, []);

  const tableChanges = (e) => {
    if (e.pageSize !== resultsOnPage) {
      setResultsOnPage(e.pageSize);
    }
  };

  const deleteUser = (userId) => dispatch(deleteUserById(userId));

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${
            dataIndex === "company"
              ? "empresa"
              : dataIndex === "user"
              ? "usuário"
              : dataIndex === "name"
              ? "nome"
              : "e-mail"
          }`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onOpenChange: (open) => {
      if (open) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#FFC400", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <Card>
      <div id="users-page">
        <Title level={4}>Usuários</Title>
        <Button
          href="/new-user"
          type="primary"
          style={{
            position: "absolute",
            zIndex: 10,
            top: "20px",
            left: "110px",
          }}
        >
          Criar Usuário
        </Button>
        <Table
          responsive={true}
          dataSource={users}
          pagination={{
            pageSize: resultsOnPage,
            showSizeChanger: true,
            size: "small",
          }}
          onChange={(e) => tableChanges(e)}
          scroll={{ y: 420 }}
          loading={tableLoading}
          columns={[
            {
              title: "Criado em",
              dataIndex: "createdat",
              sorter: (a, b) => a.timestamp - b.timestamp,
              defaultSortOrder: "descend",
              width: 130,
            },
            {
              title: "Empresa",
              dataIndex: "company",
              width: 150,
              sorter: (a, b) => {
                return a.company < b.company
                  ? -1
                  : a.company > b.company
                  ? 1
                  : 0;
              },
              ellipsis: true,
              ...getColumnSearchProps("company"),
            },
            {
              title: "Conta",
              dataIndex: "user",
              width: 120,
              sorter: (a, b) => {
                return a.user < b.user ? -1 : a.user > b.user ? 1 : 0;
              },
              ellipsis: true,
              ...getColumnSearchProps("user"),
            },
            {
              title: "Nome",
              dataIndex: "name",
              width: 120,
              sorter: (a, b) => {
                return a.name
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase() <
                  b.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                  ? -1
                  : a.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase() >
                    b.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  ? 1
                  : 0;
              },
              ellipsis: true,
              ...getColumnSearchProps("name"),
            },
            {
              title: "E-mail",
              dataIndex: "email",
              width: 220,
              sorter: (a, b) => {
                return a.email < b.email ? -1 : a.email > b.email ? 1 : 0;
              },
              ellipsis: true,
              ...getColumnSearchProps("email"),
            },
            {
              title: "Regra",
              dataIndex: "role",
              filters: [
                { text: "Admin", value: "admin" },
                { text: "CS", value: "customersuccess" },
                { text: "Comercial", value: "sale" },
                { text: "Cliente", value: "client" },
                { text: "Demo", value: "demo" },
                { text: "FTP", value: "ftp" },
              ],
              onFilter: (value, record) => record.role.indexOf(value) === 0,
              render: (row) => {
                const roleLists = {
                  admin: "Admininstrador",
                  customersuccess: "Customer Success",
                  sale: "Comercial",
                  client: "Cliente",
                  demo: "Demo",
                  ftp: "FTP",
                }[row];
                return roleLists;
              },
              width: 100,
            },
            {
              title: "Ações",
              render: (row) => {
                return (
                  <Tooltip title="Excluir usuário">
                    <Popconfirm
                      title={`Excluir o usuário ${row.name} (${row.email})?`}
                      okText="Sim"
                      cancelText="Não"
                      placement="bottomRight"
                      onConfirm={() => deleteUser(row.key)}
                      disabled={
                        !["admin", "customersuccess"].includes(token.role)
                      }
                    >
                      <Button
                        shape="circle"
                        value="small"
                        icon={<DeleteFilled />}
                        disabled={
                          !["admin", "customersuccess"].includes(token.role)
                        }
                      ></Button>
                    </Popconfirm>
                  </Tooltip>
                );
              },
              fixed: "right",
              width: 80,
            },
          ]}
        />
      </div>
    </Card>
  );
};

export default UsersPage;
