import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "general",
  initialState: {
    surveys: null,
    qlik: null,
    reload: false,
  },
  reducers: {
    setSurveysRedux(state, { payload }) {
      state.surveys = payload;
    },
    setQlikRedux(state, { payload }) {
      state.qlik = payload;
    },
    setReloadRedux(state, { payload }) {
      state.reload = payload;
    },
  },
});

export const { setSurveysRedux, setQlikRedux, setReloadRedux } = slice.actions;

export const surveysRedux = (state) => state.general.surveys;
export const qlikRedux = (state) => state.general.qlik;
export const reloadRedux = (state) => state.general.reload;

export default slice.reducer;
