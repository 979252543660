import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "bases",
  initialState: {
    bases: null,
    surveys: null,
    allSurveys: null,
    tbLoading: false,
    resultUpload: null,
    success: false,
  },
  reducers: {
    setTbLoadingRedux(state, { payload }) {
      state.tbLoading = payload;
    },
    setBasesRedux(state, { payload }) {
      state.bases = payload;
    },
    setSurveysRedux(state, { payload }) {
      state.surveys = payload;
    },
    setAllSurveysRedux(state, { payload }) {
      state.allSurveys = payload;
    },
    setResultUploadRedux(state, { payload }) {
      state.resultUpload = payload;
    },
    setSuccessRedux(state, { payload }) {
      state.success = payload;
    },
  },
});

export const {
  setTbLoadingRedux,
  setBasesRedux,
  setSurveysRedux,
  setAllSurveysRedux,
  setResultUploadRedux,
  setSuccessRedux,
} = slice.actions;

export const tbLoadingRedux = (state) => state.bases.tbLoading;
export const basesRedux = (state) => state.bases.bases;
export const surveysRedux = (state) => state.bases.surveys;
export const allSurveysRedux = (state) => state.bases.allSurveys;
export const resultUploadRedux = (state) => state.bases.resultUpload;
export const successRedux = (state) => state.bases.success;

export default slice.reducer;
