import React, { useState } from "react";
import { Button, Card, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

//redux
import { doLogin } from "../../redux/auth/auth.actions";

//styles
import "../../assets/styles/auth/login.css";

const LoginPage = () => {
  const dispatch = useDispatch();

  //local varibales
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
  });

  //set input values
  const handleChange = (e) => {
    const { value, id } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };

  //validate inputs
  const validateInputs = () => {
    if (inputValues.email === "" || inputValues.password === "") {
      toast.warning("Algum campo está vazio!");
      return false;
    }

    return true;
  };

  //fazendo o login
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      const { email, password } = inputValues;
      dispatch(
        doLogin({
          email,
          password,
        })
      );
    }
  };

  return (
    <div id="login-page" className="space-align-container">
      <Card
        className="space-align-block"
        style={{ width: 300, margin: "auto" }}
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="login"
          initialValues={{ remember: true }}
        >
          <Form.Item>
            <div className="logo-login" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Por favor, insira seu e-mail!" },
            ]}
          >
            <Input
              id="email"
              name="email"
              prefix={<UserOutlined />}
              placeholder="E-mail"
              value={inputValues.email}
              onChange={(e) => handleChange(e)}
              autoComplete="on"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Por favor, insira sua senha!" },
            ]}
          >
            <Input.Password
              id="password"
              name="password"
              prefix={<LockOutlined />}
              placeholder="Senha"
              value={inputValues.password}
              onChange={(e) => handleChange(e)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              onClick={(e) => handleSubmit(e)}
            >
              ENTRAR
            </Button>
          </Form.Item>
          <Form.Item>
            <a href="/redefinir-senha">Esqueceu a senha?</a>
          </Form.Item>
        </Form>
      </Card>
      <style type="text/css">
        {`body {
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradient 8s ease infinite;
        height: 100vh;
      }

      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }`}
      </style>
    </div>
  );
};

export default LoginPage;
